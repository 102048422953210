.error-container {
    height: 100vh;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-payku-error {
    color: #000;
    width: 80%;
    background: #fff;
    border-radius: 15px;
    margin: 40px;
    box-shadow: 2px 2px rgba(0, 0, 2, 0.3);
    transition: all ease 0.3s;
    height: auto;
}

.card-body-payku-error {
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-error {
    font-size: 60px;
    text-align: center;
}

.text-error {
    font-size: 30px;
    text-align: center;
}