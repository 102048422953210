.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    background: #e8ebef;
}


.btn-payku {
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    border-radius: 6px;
    box-shadow: 2px 2px rgba(0, 0, 2, 0.3);
    align-items: center;
    font-family: "Nunito", sans-serif;
}

.btn-payku:focus {
    outline: none;
}

.btn-primary-payku {
    background-color: var(--color-bg-secundary);
    color: var(--color-text-primary);
    margin-top: 10px;
    transition: all ease 0.3s;
}

.btn-primary-payku:hover {
    background-color: var(--color-bg-secundary-h);
    transition: all ease 0.3s;
}

.btn-primary-payku {
    font-size: 12px;
}