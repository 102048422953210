.media-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.media-item input[type="text"],
.media-item select {
  width: 100%;
  padding: 8px;
  display: inline-block;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-third);
  opacity: 0.5; /* Firefox */
  font-weight: 700;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-text-third);
  opacity: 0.5;
  font-weight: 700;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-text-third);
  opacity: 0.5;
  font-weight: 700;
}

.media-item select {
  height: 41px;
}

.media-item label {
    font-weight: 700;
      color: var(--color-text-primary);
}