.phone-input {
    display: flex;
}

.phone-input .form-control-small {
    width: 75%;
}

.phone-input .form-control-large {
    width: 85%;
}

.code:first-child {
    border-radius: 4px 0 0 4px;
}

.phone-input input {
    border-radius: 0 4px 4px 0;
    width: 75%;
}

.error {
    font-size: 12px;
    color: red;
    margin-bottom: 0px;
}