.card-payku {
    color: #000;
    width: 80%;
    background: #fff;
    border-radius: 15px;
    margin: 40px;
    box-shadow: 2px 2px rgba(0, 0, 2, 0.3);
    transition: all ease 0.3s;
    height: auto;
}

.card-body-payku {
    height: auto;
    /* padding: 20px; */
}

.theme-general {
  display: grid;
  background: var(--color-bg-primary);
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  margin-inline: 3rem;
  margin-block: 20px;
  padding-block-end: 2rem;
}

.section-theme {
  margin: 2rem;
  /*background: #e8ebef;
  */box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  /*padding: 20px;
  */border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.theme-item {
    display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
  padding-block-end: 1rem;
}

.theme-item input[type="text"],
.theme-item select {
    width: 100%;
      padding: 8px;
      display: inline-block;
      border: 2px solid #e7e7e7;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
}

  .theme-item input[type="color"] {
  width: 100%;
  padding: 3px;
  background: #fff;
  height: 41px;
  display: inline-block;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.theme-item select {
    height: 41px;
}

.theme-item label {
  font-weight: 700;
  color: var(--color-text-primary);
}

.theme-button,
.theme-header {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: 20px 0;
    padding: 20px;
    border-radius: 12px;
    grid-column-gap: 50px;
}