.ulSelected {
    list-style: none;
    padding: 0;
}

.liSelected {
    display: inline-block;
    margin-right: 0px;
}

.inputHidden {
    visibility: hidden;
}

.error {
    font-size: 12px;
    color: red;
    margin-bottom: 0px;
}