.card-body-payku {
  width: 80%;
  height: auto;
  background: var(--color-bg-primary);
  border-radius: 20px;
  margin: 40px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  transition: all ease 0.3s;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.header-payku {
  min-height: 4rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  padding-inline: 3rem;
  align-items: center;
  flex-wrap: wrap;
  background: var(--color-bg-secundary);
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20 20 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -ms-border-radius: 20px 20px 0 0;
  -o-border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
}

.tittle-form-sub {
  font-size: clamp(1.2rem, 2vw, 1.8rem);
  font-weight: 700;
  color: var(--color-text-primary);
}

.template-section {
  display: grid;
  background: var(--color-bg-primary);
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  margin-inline: 3rem;
  margin-block: 20px;
  margin-block-end: 2rem;
}

.template-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.template-item input[type="text"],
.template-item select {
  width: 100%;
  padding: 8px;
  display: inline-block;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-third);
  opacity: 0.5; /* Firefox */
  font-weight: 700;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-text-third);
  opacity: 0.5;
  font-weight: 700;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-text-third);
  opacity: 0.5;
  font-weight: 700;
}

.template-item select {
  height: 41px;
}

.template-item label {
  font-weight: 700;
  color: var(--color-text-primary);
}

.template-item label span {
  font-size: 0.9rem;
  font-weight: 400;
  margin-inline-start: 1rem;
  color: var(--color-text-third);
}

.last {
  grid-column-end: -1;
}

.template-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn-payku {
  border: none;
  padding: 10px 20px;
  text-align: center;
  background: var(--color-bg-secundary) !important;
  display: flex;
  order: 1;
  font-size: 16px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  align-items: flex-end;
  font-family: "Nunito", sans-serif;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.btn-payku:hover {
background: var(--color-bg-secundary-h) !important;
}

.btn-payku:focus {
  outline: none;
}

.btn-danger-payku {
  background: var(--color-bg-denger) !important;
  color: white;
  margin: 0 10px;
  padding: 10px 15px;
  border-radius: 6px;
  transition: all ease 0.3s;
}

.btn-danger-payku:hover {
  background: var(--color-bg-denger-h) !important;
}

.container-button {
  width: 100%;
  height: 4rem;
  margin-bottom:0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-primary-payku {
  font-size: 12px;
}

.separator-line {
margin-inline: 3rem;
border: 0.5px solid var(--color-text-primary);
opacity: 0.2;
border-radius: 10px;
}