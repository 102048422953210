.card-body-payku {
  width: 80%;
  height: auto;
  background: var(--color-bg-primary);
  border-radius: 20px;
  margin: 40px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  transition: all ease 0.3s;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.header-payku {
  min-height: 3rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  padding-inline: 3rem;
  padding-block: 0.7rem;
  align-items: center;
  flex-wrap: wrap;
  background: var(--color-bg-secundary);
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20 20 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -ms-border-radius: 20px 20px 0 0;
  -o-border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
}

.tittle-form-sub {
  font-size: clamp(1.2rem, 2vw, 1.8rem);
  font-weight: 700;
  color: var(--color-text-primary);
}

.btn-section-admin {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-payku:focus {
    outline: none;
}

.btn-primary-payku-view {
    border: none;
    padding: 10px 20px;
    margin-inline-end: 0.7rem;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: clamp(1rem, 1.5vw, 1.4rem);
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    font-family: "Nunito", sans-serif;
    background-color: var(--color-text-primary);
    color: var(--color-bg-secundary);
    transition: all ease 0.3s;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.btn-primary-payku-view:hover {
  background: var(--color-text-primary-h);
}

.btn-primary-payku-updated {
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: clamp(0.7rem, 1vw, 1.2rem);
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    font-family: "Nunito", sans-serif;
    line-height: clamp(1rem, 1.5vw, 1.4rem);
    background-color: var(--color-text-primary);
    color: var(--color-bg-secundary);
    transition: all ease 0.3s;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.btn-primary-payku-updated:hover {
  background: var(--color-text-primary-h);
}

.btn-primary-payku:hover {
    background-color: var(--color-bg-secundary-h);
    transition: all ease 0.3s;
}

.activeForm {
  width: 100%;
  padding: 8px 20px;
  margin-block: 1rem;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.theme-general {
  display: grid;
  background: var(--color-bg-primary);
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  margin-inline: 3rem;
  margin-block: 20px;
  margin-block-end: 2rem;
}

.general-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.general-item input[type="text"],
.general-item select {
  width: 100%;
  padding: 8px;
  display: inline-block;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.general-item select {
  height: 41px;
}

.general-item label {
  font-weight: 700;
  color: var(--color-text-primary);
}

.general-item label span {
  font-size: 0.9rem;
  font-weight: 400;
  margin-inline-start: 1rem;
  color: var(--color-text-third);
}