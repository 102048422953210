.card-body-payku {
  width: 80%;
  height: auto;
  background: var(--color-bg-primary);
  border-radius: 20px;
  margin: 40px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  transition: all ease 0.3s;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.card-body-payku-sub {
  width: 100%;
  height: auto;
  background: var(--color-bg-primary);
  border-radius: 20px;
  border: 3px solid var(--color-bg-secundary);
  transition: all ease 0.3s;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.header-payku {
  min-height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: var(--color-bg-secundary);
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -ms-border-radius: 20px 20px 0 0;
  -o-border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
}

.header-payku-sub {
  min-height: 2rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  /* padding-inline: 3rem; */
  align-items: center;
  flex-wrap: wrap;
  outline: 3px solid var(--color-bg-secundary);
  background: var(--color-bg-primary);
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -ms-border-radius: 20px 20px 0 0;
  -o-border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
}

.plan-section {
  margin: 2rem;
  /*background: #e8ebef;
  */box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  /*padding: 20px;
  */border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.tittle-form-sub {
  font-size: clamp(1.2rem, 2vw, 1.8rem);
  font-weight: 700;
  color: var(--color-text-primary);
}

.tittle-form-sub-sub {
  font-size: clamp(1rem, 1.5vw, 1.4rem);
  font-weight: 700;
  color: var(--color-text-primary);
}

.tittle-form-sub-sub2 {
    padding-inline-start: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--color-text-primary);
}

.plan-info {
  display: grid;
  background: var(--color-bg-primary);
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  margin-inline: 3rem;
  margin-block: 20px;
  margin-block-end: 2rem;
}

.plan-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.plan-item input[type="text"],
.plan-item select,
.plan-item.plan-item-subscription input[type="text"],
.plan-item.plan-item-subscription select {
  width: 100%;
  padding: 8px;
  display: inline-block;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-third);
  opacity: 0.5; /* Firefox */
  font-weight: 700;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-text-third);
  opacity: 0.5;
  font-weight: 700;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-text-third);
  opacity: 0.5;
  font-weight: 700;
}

.plan-item select {
  height: 41px;
}

.plan-item label {
  font-weight: 700;
  color: var(--color-text-primary);
}

.plan-item.plan-item-subscription {
  justify-content: center;
}

.plan-suscription {
  display: grid;
  background: var(--color-text-secundary);
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 0.4rem;
  margin-inline: 3rem;
  margin-block: 1rem;
  margin-block-end: 2rem;
}

.plan-group {
width: 100%;
  padding: 1rem;
  /* background: #fff; */
  border-radius: 12px;
  transition: all ease 0.3s;
  display: flex;
  /* justify-content: space-around; */
  flex-direction: column;
}

.btn-payku {
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
  border-radius: 6px;
  box-shadow: 2px 2px rgba(0, 0, 2, 0.3);
  align-items: center;
  font-family: "Nunito", sans-serif;
}

.btn-payku:focus {
  outline: none;
}

.btn-danger-payku {
  background-color: #fc3f52;
  color: white;
  margin: 0 10px;
  padding: 10px 15px;
  border-radius: 6px;
  transition: all ease 0.3s;
}

.btn-danger-payku-sub {
  border: none;
  outline: 3px solid var(--color-bg-denger);
  background-color: var(--color-bg-denger) !important;
  color: white;
  padding: 10px 15px;
  transition: all ease 0.3s;
  border-radius: 0 20px 0 0;
  -webkit-border-radius: 0 16px 0 0;
  -moz-border-radius: 0 16px 0 0;
  -ms-border-radius: 0 16px 0 0;
  -o-border-radius: 0 16px 0 0;
  z-index: 999;
}

.btn-danger-payku-sub:hover {
  outline: 3px solid var(--color-bg-denger-h);
  background-color: var(--color-bg-denger-h) !important;
  transition: all ease 0.3s;
}

.btn-danger-payku-sub:focus {
  outline: 3px solid var(--color-bg-denger-h);
  background-color: var(--color-bg-denger-h) !important;
  transition: all ease 0.3s;
}

.btn-delete-payku {
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
  border-radius: 6px;
  box-shadow: 2px 2px rgba(0, 0, 2, 0.3);
  align-items: center;
  font-family: "Nunito", sans-serif;
  background-color: var(--color-bg-denger) !important;
  color: white;
  margin: 10px 10px 0 0;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
}

.btn-delete-payku:hover,
.btn-danger-payku:hover {
  background-color: var(--color-bg-denger-h) !important;
  transition: all ease 0.3s;
}

.btn-delete-payku:focus,
.btn-danger-payku:focus {
  background-color: var(--color-bg-denger-h) !important;
  transition: all ease 0.3s;
}

.btn-section {
  justify-content: center;
  padding-bottom: 1.7rem;
}

.btn-section .btn-delete-payku,
.btn-section .btn-primary-payku {
  font-size: 12px;
}

.separator-line {
  margin-inline: 3rem;
  border: 0.5px solid var(--color-text-primary);
  opacity: 0.2;
  border-radius: 10px;
}
