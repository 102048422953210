:root{
  --color-text-primary: #0C5460;
  --color-text-primary-h: #023d47;
  --color-text-secundary: #ffffff;
  --color-text-third: #afafaf;
  --color-bg-primary: #ffffff;
  --color-bg-secundary: #d1ecf1;
  --color-bg-secundary-h: #abd4db;
  --color-bg-denger: #dc1414;
  --color-bg-denger-h: #aa0606;
}

body {
  background-color: var(--color-text-third);
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-third);
  opacity: 0.5; /* Firefox */
  font-weight: 700;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-text-third);
  opacity: 0.5;
  font-weight: 700;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-text-third);
  opacity: 0.5;
  font-weight: 700;
}

input {
  caret-color: var(--color-text-primary);
}

/*Tipos de botones////////////////////////////////////////////////////*/
.circle-btn {
  display: inline-block;
  line-height: 70px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-top: 15px;
}

.oval-btn {
  display: inline-block;
  line-height: 35px;
  width: 70px;
  height: 35px;
  border-radius: 50%;
  margin-top: 15px;
}

.pills-btn {
  display: inline-block;
  font-size: 0.9rem;
  line-height: 30px;
  width: 70px;
  height: 35px;
  border-radius: 25px;
  margin-top: 15px;
}

.rounded-btn {
  display: inline-block;
  line-height: 35px;
  width: 70px;
  height: 35px;
  border-radius: 25px;
  margin-top: 15px;
}

.containerBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.containerBox:hover input ~ .checkmark {
  border: 0.1rem solid blue;
}

.containerBox input:checked ~ .checkmark {
  background-color: blue;
}

.containerBox input:checked ~ .checkmark:after {
  display: block;
}

.containerBox .checkmark:after {
  left: 2.5px;
  top: 0px;
  width: 3.5px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-section {
  display: flex;
  justify-content: flex-end;
}

.code {
  text-align: center;
  font-size: 16px;
  height: 38px;
  line-height: 38px;
  color: #000;
  background: #fafafa;
  border: 1px solid #ccc;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}

.code-small {
  width: 25%;
}
